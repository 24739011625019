// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/channel/components/ChannelDropdown.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/channel/components/ChannelDropdown.tsx");
  import.meta.hot.lastModified = "1731679026356.6426";
}
// REMIX HMR END

import React from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Avatar, Button, NativeSelect, Pill } from '~ui';
import clsx from 'clsx';
import { ChannelSwitchFetcher } from '~admin/modules/channel/fetchers/channel-switch-fetcher';
import { defaultIdAccessor } from '~common/components/form/autocomplete';
import { offset, shift, useFloating } from '@floating-ui/react';
import { DEFAULT_CHANNEL_TOKEN } from '../config';
export const selectRenderer = {
  idAccessor: defaultIdAccessor,
  labelAccessor: item => item ? `${item.code} (${item.token})` : null
};
const channelSort = (c1, c2) => {
  if (c1.code === DEFAULT_CHANNEL_TOKEN) {
    return -1;
  }
  if (c2.code === DEFAULT_CHANNEL_TOKEN) {
    return 1;
  }
  if (c1.code < c2.code) {
    return -1;
  }
  if (c1.code > c2.code) {
    return 1;
  }
  return 0;
};
export const ChannelDropdown = ({
  className = 'text-gray-400'
}) => {
  _s();
  const {
    refs,
    floatingStyles
  } = useFloating({
    placement: 'bottom-end',
    transform: false,
    middleware: [offset({
      mainAxis: 10,
      crossAxis: 0,
      alignmentAxis: 0
    }), shift()]
  });
  return <ChannelSwitchFetcher>
      {({
      channels,
      channelToken,
      loading,
      exception,
      onChange
    }) => {
      let content = () => null;
      let channelIcon;
      let selectedChannel;
      if (channelToken) {
        selectedChannel = <Pill variant="warn" icon={<XMarkIcon className="w-4 h-4" aria-hidden="true" />} onClick={() => onChange(null)}>
              {channelToken.name !== DEFAULT_CHANNEL_TOKEN ? channelToken.name : 'Default channel'}
            </Pill>;
        channelIcon = <div className="bg-primary-600 rounded-full" title={channelToken.name}>
              <Avatar size="xs" type="circular" source={channelToken.avatar} alt={channelToken.name} altContent={channelToken.name} bgColor="bg-warn-600" textColor="text-white" />
            </div>;
      } else {
        channelIcon = <div className="bg-primary-600 rounded-full" title="Default channel">
              <Avatar size="xs" type="circular" alt="Select channel" altContent="C" bgColor="bg-info-600" textColor="text-white" />
            </div>;
      }
      if (loading) {
        // content = () => null
      } else if (exception) {
        // content = () => null
      } else if (channels) {
        // if (channels.totalItems < 2) {
        //   // nothing to select
        //   return null
        // }
        const channelSorted = channels?.items.sort(channelSort);
        content = ({
          close
        }) => <>
              {channelSorted.slice(0, 5).map(channel => {
            const c = channel;
            return <Button key={c.token} onClick={() => {
              onChange({
                name: c.code,
                token: c.token,
                avatar: c.customFields?.storeIcon?.preview,
                timezone: c.customFields?.timezone
              });
              close();
              // navigate(".", {
              //   replace: true,
              //   relative: "path",
              // })
            }} className={clsx('w-full block text-left py-3 px-3 py-1 text-sm font-semibold leading-6 text-gray-900 dark:text-slate-400 dark:hover:bg-slate-700')} variant="unstyled">
                    {c.code !== DEFAULT_CHANNEL_TOKEN ? c.code : 'Default channel'}{' '}
                    <span className="font-normal">({c.token})</span>
                  </Button>;
          })}
              {channels?.totalItems > 5 ? <div className="flex items-center">
                  <NativeSelect name="selectedChannelId" onChange={e => {
              const token = e.currentTarget.value;
              const channel = channels.items.find(c => c.token === token);
              onChange({
                name: channel.code,
                token: channel.token,
                avatar: channel.customFields?.storeIcon?.preview,
                timezone: channel.customFields?.timezone
              });
              close();
              // navigate(".", {
              //   replace: true,
              //   relative: "path",
              // })
            }}>
                    {channelSorted.slice(5).map(c => {
                const channel = c;
                return <option key={channel.code} value={channel.token}>
                          {channel.code !== DEFAULT_CHANNEL_TOKEN ? channel.code : 'Default channel'}{' '}
                          ({channel.token})
                        </option>;
              })}
                  </NativeSelect>
                  {/* <SimpleAutocompleteSelect
                    renderer={selectRenderer}
                    selectedItem={null}
                    data={channelSorted.slice(5)}
                    onSelect={(c: Channel) => {
                      onChange({
                        name: c.code,
                        token: c.token,
                        avatar: c.customFields?.storeIcon?.preview,
                        timezone: c.customFields?.timezone,
                      })
                    }}
                   /> */}
                </div> : null}
            </>;
      }
      const channelCount = channels?.items?.length ?? 0;
      return <Popover>
            {({
          close
        }) => <>
                <PopoverButton
          // @ts-ignore hmm-kay
          ref={refs.setReference} className={clsx('focus:outline-none -m-1.5 flex items-center p-1.5 inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-slate-400', className)} disabled={channelCount < 2}>
                  <span className="sr-only">Open channel menu</span>
                  {channelIcon}
                  {channelCount > 1 ? <span className="hidden lg:flex lg:items-center">
                      <ChevronDownIcon className={clsx('ml-2 h-5 w-5 text-gray-400', className)} aria-hidden="true" />
                    </span> : null}
                </PopoverButton>

                <Transition as={React.Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
                  <PopoverPanel
            // @ts-ignore hmm-kay
            ref={refs.setFloating}
            // className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
            style={floatingStyles}>
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl text-sm leading-6 shadow-lg ring-1 bg-white dark:bg-slate-900 ring-gray-900/5 dark:ring-slate-500">
                      <div className="p-8">
                        <div className="flex items-center justify-between">
                          <h3 className="text-sm font-semibold leading-6 text-gray-500">
                            Channels
                          </h3>
                          {selectedChannel}
                        </div>

                        <div>{content({
                      close
                    })}</div>
                      </div>
                    </div>
                  </PopoverPanel>
                </Transition>
              </>}
          </Popover>;
    }}
    </ChannelSwitchFetcher>;
};
_s(ChannelDropdown, "yf5c1DUaoqCxhOjCmzgGhV8T+Fs=", false, function () {
  return [useFloating];
});
_c = ChannelDropdown;
var _c;
$RefreshReg$(_c, "ChannelDropdown");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;