// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/layout/Searchbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/layout/Searchbar.tsx");
  import.meta.hot.lastModified = "1731679026347.6418";
}
// REMIX HMR END

import React from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { LoginDropdown } from '~admin/modules/account-settings/components/LoginDropdown';
import { ChannelDropdown } from '~admin/modules/channel/components/ChannelDropdown';
import { NotificationButton } from '~admin/modules/notifications/components/NotificationButton';
import clsx from 'clsx';
import { CustomersAutocomplete } from '~admin/modules/customer/components/CustomersAutocomplete';
import { useNavigate } from '@remix-run/react';
import { config as customerConfig } from '~admin/modules/customer/config';
import { DEFAULT_CHANNEL_TOKEN } from '~admin/modules/channel/config';
export const Searchbar = ({
  administrator,
  channel,
  socketActive,
  onSignOut,
  onSidebarOpen
}) => {
  _s();
  const navigate = useNavigate();
  const handleSelectCustomer = React.useCallback(item => {
    if (item) {
      navigate(`${customerConfig.path}/${item.id}/details`, {
        replace: true
      });
    }
  }, []);
  const isDefaultChannel = channel?.code === DEFAULT_CHANNEL_TOKEN;
  return <div className={clsx('fixed min-w-full md:min-w-full md:sticky top-0 right-0 z-20 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-slate-500 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8', {
    'bg-white dark:bg-slate-900': !isDefaultChannel,
    'bg-[#E5B49B] dark:bg-indigo-800 dark:border-b dark:border-error-800': isDefaultChannel
  })}>
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 dark:text-slate-400 lg:hidden" onClick={onSidebarOpen}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-900/10 dark:bg-slate-800 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="-mt-1.5 relative flex flex-1 items-center">
          <MagnifyingGlassIcon className={clsx('pointer-events-none h-full w-5 mt-1.5 mr-3', {
          'text-gray-700 dark:text-slate-400': isDefaultChannel,
          'text-gray-400 dark:text-slate-300': !isDefaultChannel
        })} // absolute inset-y-0 left-0
        aria-hidden="true" />
          <CustomersAutocomplete noBorder showExistingButton={false} placeholder="Search" className="w-full" selectedItem={null} onSelect={handleSelectCustomer} />
        </div>
        {/* <form className="relative flex flex-1" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-slate-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 dark:text-slate-400 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
         </form> */}
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <div className="flex items-center justify-center" title={socketActive ? 'Alerts active' : 'Alerts not active'}>
            <div className={clsx('w-2 h-2 rounded-full', {
            'bg-red-600': !socketActive,
            'bg-green-600': socketActive
          })}></div>
          </div>
          <NotificationButton className={clsx({
          'text-gray-700 hover:text-gray-800 dark:text-slate-400 dark:hover:text-slate-300': isDefaultChannel,
          'text-gray-400 hover:text-gray-500 dark:text-slate-300 dark:hover:text-slate-200': !isDefaultChannel
        })} />
          <ChannelDropdown className={clsx({
          'text-gray-700 hover:text-gray-800 dark:text-slate-400 dark:hover:text-slate-300': isDefaultChannel,
          'text-gray-400 hover:text-gray-500 dark:text-slate-300 dark:hover:text-slate-200': !isDefaultChannel
        })} />
          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:bg-slate-800" aria-hidden="true" />
          <LoginDropdown administrator={administrator} onSignOut={onSignOut} className={clsx({
          'text-gray-700 hover:text-gray-800 dark:text-slate-400 dark:hover:text-slate-300': isDefaultChannel,
          'text-gray-400 hover:text-gray-500 dark:text-slate-300 dark:hover:text-slate-200': !isDefaultChannel
        })} />
        </div>
      </div>
    </div>;
};
_s(Searchbar, "2QmlSO/hm2kURoPDX154uQ6E7Tc=", false, function () {
  return [useNavigate];
});
_c = Searchbar;
var _c;
$RefreshReg$(_c, "Searchbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;